/* eslint-disable*/
import VueStoreService from './VueStoreService';
const TemplateService = {
    async getTemplates(filter,store){
        var resp = await VueStoreService.get("api/template"+filter, store)
        return resp;
    },
    async saveTemplate(data,store){
        var resp = await VueStoreService.post("api/template",data,store)
        return resp;
    },
    async getTemplate(template_id,store){
        var resp = await VueStoreService.get( "api/template/"+template_id,store)
        return resp;
    },
    async updateTemplate(template_id,data,store){
        var resp = await VueStoreService.put("api/template/"+template_id,data,store)
        return resp;
    },

    async deleteTemplate(template_id,store){
        var resp = await VueStoreService.delete("api/template/"+template_id,store)
        return resp;
    },
}

export default TemplateService;